import { useNavigate } from "react-router-dom";
import useVH from "react-viewport-height";
import MotionDiv from "../../components/motion/MotionDiv";
import BackButton from "../../components/UI/buttons/BackButton";
import NetworkIndicator from "./NetworkIndicator";
import ProgressBar from "./ProgressBar";
import SwipeField from "./SwipeField";

const Swipe = () => {
  useVH();
  let navigate = useNavigate();

  return (
    <MotionDiv>
      <div className="bg-space-blue height100vh">
        <ProgressBar />
        <SwipeField />
        <NetworkIndicator />
        <div className="absolute top-0 left-0 z-40">
          <BackButton onClick={() => navigate("/tutorial")} />
        </div>
      </div>
    </MotionDiv>
  );
};

export default Swipe;
