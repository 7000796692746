import { useNavigate } from "react-router-dom";
import useVH from "react-viewport-height";
import { motion } from "framer-motion";
import MotionDiv from "../../components/motion/MotionDiv";
import Button from "../../components/UI/buttons/Button";

import bg from "../../assets/img/00_general/bg.jpeg";
import usability1 from "../../assets/img/04_verify/verify_usability-01.svg";
import usability2 from "../../assets/img/04_verify/verify_usability-02.svg";

import logo from "../../assets/img/00_general/prismade.svg";

const Failure = () => {
  useVH();
  let navigate = useNavigate();

  const staggerFadeIn = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 1,
        delayChildren: 0.5,
        staggerChildren: 1,
      },
    },
  };

  return (
    <MotionDiv>
      <div
        className="flex flex-col items-center justify-between pt-10 pb-8 text-center text-white bg-center bg-cover px-14 minHeight100vh"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="text-2xl">
          Banknote could not
          <br />
          be authenticated.
        </div>

        <motion.div variants={staggerFadeIn} initial="hidden" animate="show">
          <motion.div variants={staggerFadeIn}>
            <img src={usability1} alt="" className="h-32 mx-auto mt-10" />
            <p className="mt-4">Place banknote on screen and hold the banknote with your thumb.</p>
          </motion.div>
          <motion.div variants={staggerFadeIn}>
            <img src={usability2} alt="" className="h-32 mx-auto mt-10" />
            <p className="mt-4">Swipe up over the marked stripe of the banknote.</p>
          </motion.div>
        </motion.div>

        <div className="mt-8">
          <Button onClick={() => navigate("/")} title="Start Again" className="w-56 mx-auto" />
        </div>

        <img src={logo} alt="Prismade Logo" className="h-6 mx-auto mt-8" />
      </div>
    </MotionDiv>
  );
};

export default Failure;
