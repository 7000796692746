import { useAppSelector } from "../../../state/hooks";
import { RootState } from "../../../state/store";
import { motion } from "framer-motion";

import bg from "../../../assets/img/00_general/bg.jpeg";

const ProgressBar = () => {
  const progress = useAppSelector((state: RootState) => state.swipe.progress);

  return (
    <motion.div animate={{ scale: 1 + progress / 100 }}>
      <img src={bg} alt="" className="w-screen h-screen touch-none" />
    </motion.div>
  );
};

export default ProgressBar;
