import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import useVH from "react-viewport-height";
import MotionDiv, { MotionDivVariant } from "../../components/motion/MotionDiv";
import BackButton from "../../components/UI/buttons/BackButton";
import Button from "../../components/UI/buttons/Button";

import bg from "../../assets/img/00_general/bg.jpeg";
import instruction1 from "../../assets/img/02_onboarding/onboarding-banknote-01.svg";
import instruction2 from "../../assets/img/02_onboarding/onboarding-banknote-02.svg";

const Tutorial = () => {
  useVH();
  let navigate = useNavigate();
  const [nextSlide, setNextSlide] = useState<boolean>();

  const dotVariants = {
    full: { width: 18, height: 18, backgroundColor: "#FFFFFFFF" },
    empty: { width: 12, height: 12, backgroundColor: "#FF000000" },
  };

  return (
    <MotionDiv>
      <div
        className="flex flex-col items-center w-full pt-10 pb-10 text-white bg-center bg-cover height100vh"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="absolute top-2 left-2">
          <BackButton
            onClick={() => {
              if (!nextSlide) {
                navigate("/", { replace: true });
              } else {
                setNextSlide(nextSlide ? !nextSlide : true);
              }
            }}
          />
        </div>

        <div className="mt-10">
          <div className="flex items-center justify-center w-screen">
            {!nextSlide && (
              <MotionDiv key="slide1">
                <span className="text-4xl">Place Banknote</span>
              </MotionDiv>
            )}
            {nextSlide && (
              <MotionDiv key="slide2">
                <span className="text-4xl">Swipe</span>
              </MotionDiv>
            )}
          </div>
        </div>

        <div className="flex flex-col items-center justify-center flex-grow w-screen">
          <AnimatePresence exitBeforeEnter>
            {!nextSlide && (
              <MotionDiv
                initial={nextSlide != null ? MotionDivVariant.InitialOnLeft : MotionDivVariant.InitialVisible}
                animate={MotionDivVariant.SlideInHorizontal}
                exit={MotionDivVariant.SlideOutToLeft}
                key="image1"
                className="flex-grow w-screen bg-center bg-no-repeat bg-contain"
                style={{ backgroundImage: `url(${instruction1})` }}
              ></MotionDiv>
            )}
            {nextSlide && (
              <MotionDiv
                initial={MotionDivVariant.InitialOnRight}
                animate={MotionDivVariant.SlideInHorizontal}
                exit={MotionDivVariant.SlideOutToRight}
                key="image2"
                className="flex-grow w-screen bg-center bg-no-repeat bg-contain"
                style={{ backgroundImage: `url(${instruction2})` }}
              ></MotionDiv>
            )}
          </AnimatePresence>
        </div>

        <div className="flex items-center h-8 my-4 space-x-3">
          <AnimatePresence>
            <motion.div
              key="dot1"
              className="border-2 border-white rounded-full"
              variants={dotVariants}
              animate={nextSlide ? "empty" : "full"}
              transition={{ type: "linear" }}
            ></motion.div>
            <motion.div
              key="dot2"
              className="border-2 border-white rounded-full"
              variants={dotVariants}
              animate={!nextSlide ? "empty" : "full"}
              transition={{ type: "linear" }}
            ></motion.div>
          </AnimatePresence>
        </div>
        <Button
          title={!nextSlide ? "Continue" : "Start"}
          onClick={() => {
            if (!nextSlide) {
              setNextSlide(nextSlide ? !nextSlide : true);
            } else {
              navigate("/swipe", { replace: true });
            }
          }}
          className="w-56 uppercase"
        />
      </div>
    </MotionDiv>
  );
};

export default Tutorial;
