import { PrismaSDK } from "@prismadelabs/prismaid";

class SDKSingleton {
  private static instance: SDKSingleton;
  sdk: PrismaSDK;

  constructor() {
    if (SDKSingleton.instance) {
      throw new Error("Error - use SDKSingleton.getInstance()");
    }
    this.sdk = new PrismaSDK(
      process.env.REACT_APP_SDK_API_KEY!,
      "https://api-dev.prismade.net/prismaid" // FIXME: remove dev-url
    );
    this.sdk.setTwoFingerHoldingMode(true);
  }

  static getInstance(): SDKSingleton {
    SDKSingleton.instance = SDKSingleton.instance || new SDKSingleton();
    return SDKSingleton.instance;
  }
}

export default SDKSingleton;
