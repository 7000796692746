import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useVH from "react-viewport-height";
import { useAppDispatch } from "../../state/hooks";
import { setInvocationIsValid } from "../../state/slices/app";
import MotionDiv from "../../components/motion/MotionDiv";
import Button from "../../components/UI/buttons/Button";

import bg from "../../assets/img/00_general/bg.jpeg";
import logo from "../../assets/img/00_general/prismade.svg";

const Start = () => {
  useVH();
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(setInvocationIsValid());
  }, []);

  return (
    <MotionDiv>
      <div
        className="flex flex-col items-center justify-between pb-10 text-white bg-center bg-cover pt-14 height100vh"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <h1 className="text-5xl leading-snug">
          Interactive
          <br />
          Banknotes
        </h1>

        <img src={logo} alt="Prismade Logo" className="h-10" />
        <div></div>
        <Button
          onClick={() => {
            navigate("/tutorial");
          }}
          title="Start"
          className="w-56 uppercase shadow-lg"
        />
      </div>
    </MotionDiv>
  );
};

export default Start;
