import useVH from "react-viewport-height";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import MotionDiv from "../../components/motion/MotionDiv";
import Button from "../../components/UI/buttons/Button";

import bg from "../../assets/img/00_general/bg.jpeg";
import bill from "../../assets/img/04_verify/verify_banknote.png";
import logo from "../../assets/img/00_general/prismade.svg";

import learn from "../../assets/files/prismade_web_introduction.pdf";

const Success = () => {
  useVH();
  let navigate = useNavigate();

  return (
    <MotionDiv>
      <div
        className="z-10 flex flex-col items-center justify-between col-start-1 row-start-1 px-8 pt-8 pb-8 text-white bg-center bg-cover minHeight100vh"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="text-2xl text-center">
          <p className="pt-4">Banknote has been authenticated.</p>
        </div>

        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: [0.8, 1.2, 1] }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 90,
            delay: 0.7,
          }}
        >
          <img src={bill} alt="bill" className="h-64 mx-auto mt-6 mb-6" />
        </motion.div>

        <div className="grid justify-center grid-cols-1 gap-5">
          <div className="w-full text-center">
            <a
              href={learn}
              className="inline-flex items-center justify-center w-56 px-3 py-3 text-center bg-white rounded-full text-prismade-blue"
            >
              <div className="w-full text-2xl">Learn more</div>
            </a>
          </div>

          <Button
            // reset state to empty, otherwise it will throw an error
            onClick={() => navigate("/", { state: {} })}
            title="Start Again"
            className="w-56 mx-auto"
          />
        </div>

        <img src={logo} alt="Logo Prismade" className="h-6 mx-auto mt-6" />
      </div>
    </MotionDiv>
  );
};

export default Success;
